import * as $ from 'jquery'

$(function() {
  const $form = $("form.subscribe");
  const $checkbox = $form.find("input[type=checkbox]");
  const $button = $form.find("button");
  const $checkboxText = $form.find(".subscribeContainer span");
  const $goTo = $(".testersGoTo")

  // default state in case of browser reload
  $checkbox.prop('checked', false);

  // toggle disabled state on button
  $checkbox.on("change", function() {
    $button.toggleClass("disabled", !$checkbox.is(":checked"));
  });

  // check checkbox when description text is clicked
  $checkboxText.on("click", function() {
    $checkbox.trigger("click");
  });

  // handle redirect on form submit
  // we don't use default form action, as it adds "?"
  // and possibly other query params to the URL
  $form.on("submit", function() {
    if ($checkbox.is(":checked")) {
      window.open($form.attr("action"), '_blank');
    }

    return false;
  });

  $goTo.click(function () {
    $([document.documentElement, document.body]).animate({
      scrollTop: $form.offset().top
    }, 1000);
  });

  $('.azan-wrapper').css('min-height', $(document).height());
});
